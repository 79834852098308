<template>
  <div>
    <div class="welcome-text"> 
      Bem vindo(a), 
      <span class="text-capitalize"> 
        {{ userName }}.
      </span> 
      <br>
      <span v-if="isFreemium">
        Acesso grátis <b>{{ freemiumRemainingTime }}</b> dias restantes, <b style="cursor: pointer" @click="goToPreInterview">faça o seu diagnóstico gratuito aqui.</b>
      </span>
    </div>
    <NavMenu></NavMenu>
    <router-view></router-view>
  </div>
</template>

<script>
import { RouterView } from "vue-router";
import NavMenu from "../../layout/Planning/NavMenu.vue";
import { useAppStoreFuture } from "@/store/future/store.js";
import { useAppStore } from "@/store/store.js";

export default {
  data() {
    return {
      plan: "",
    };
  },
  created() {
    const store = useAppStoreFuture();
    store.get_planning_setting();
    store.get_objective();
    if (this.last_planning_setting) {
      this.planning_setting = {
        ...this.last_planning_setting,
        inflation_rate: parseFloat(this.last_planning_setting.inflation_rate),
        interest_rate: parseFloat(this.last_planning_setting.interest_rate),
      };
    }
  },
  computed: {
    planning_setting_data() {
      const store = useAppStoreFuture();
      const data = store.planning_setting;

      return data;
    },
    last_planning_setting() {
      const store = useAppStoreFuture();
      const data = store.last_planning_setting;

      return data;
    },
    userName() {
      const store = useAppStore();
      const user = store.userResponse.data;
      if (user) {
        return user.userFastapp?.name || 'Usuário';
      }
      return 'Usuário'
    },
    isFreemium() {
      const store = useAppStore();
      let user = store.userResponse?.data?.userInfo;
      if(!user?.financial_management_active &&
        (user?.is_pn_employee || user?.other_running_products)
      ){
        return false
      }
      return store.hasFreemiumAccess;
    },
  freemiumRemainingTime() {
    const store = useAppStore();
    return store.freemiumRemainingTime;
  }
  },
  components: { NavMenu },
  methods: {
    async goToPreInterview(route) {
      const store = useAppStore();
      await store.goToPreInterview();
    },
  }
};
</script>
<style scoped>
  .welcome-text {
    font-size: small;
    color: white;
  }
  b {
    color: #cb5540  
  }
</style>
